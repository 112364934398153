<template>
  <div>
    <v-card dark tile color="#795548">
      <div class="d-flex flex-no-wrap justify-space-between" style="padding-top: 3px; max-height: 40px">
        <v-avatar size="35" tile>
          <v-icon>fa-atom</v-icon>
        </v-avatar>
        <div style="flex-grow: 1">
          <v-card-title class="text-body1 pa-0 pl-5 pt-1 pb-2" style="font-weight: 350; display: inline-block; max-width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{
            item.ui.title
          }}</v-card-title>
          <!-- <v-card-subtitle class="pl-5">{{item.ui.description}}</v-card-subtitle> -->
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      connectionState: "connected",
      item: this.$store.device
    };
  }
};
</script>